<template>
  <div class="order-create__section">
    <TypeReturnBlock
        v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value && this.ordersInbound.data.idReturned === null"
        :ordersInbound="ordersInbound"
        :blockLabelChoice="blockLabelChoice"
        :edit="edit"
        @typeReturnChanged="$emit('typeReturnChanged')"
    />
<!--    <ShippingCompanyBlock-->
<!--        v-if="($route.params.type !== ORDER_INBOUND_TYPE.FBMReturn.value) || isAdmin"-->
<!--        :ordersInbound="ordersInbound"-->
<!--    />-->
    <ShippingDetailsBlock
            v-if="isAdmin && ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType"
            :ordersInbound="ordersInbound"
            :personal="ordersInbound.data.Personal"
            :delivery="ordersInbound.data.Delivery"
    />
    <ShipmentDetailsBlock
        :ordersInbound="ordersInbound"
    />
<!--    ($route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value && ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType) ||-->
    <DocumentsBlock
        v-if="$route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"
        :ordersInbound="ordersInbound"
        :documents="ordersInbound.data.Documents"
    />
    <CommentsBlock
        :ordersInbound="ordersInbound"
    />

    <div class="order-create__row custom-row"
         v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||
              $route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"
    >
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_checkAndRepack'])"></div>
        <DefaultCheckbox
            class="wfc"
            :label="$t('ordersInbound_checkAndRepack.localization_value.value')"
            :value="ordersInbound.data.checkAndRepack"
            @change.native="ordersInbound.data.checkAndRepack = !ordersInbound.data.checkAndRepack"
        />
      </div>
    </div>


  </div>
</template>

<script>
// import ShippingCompanyBlock from "../chunks/ShippingCompanyBlock/ShippingCompanyBlock";
import ShipmentDetailsBlock from "../chunks/ShipmentDetailsBlock/ShipmentDetailsBlock";
import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
import DocumentsBlock from "../chunks/DocumentsBlock/DocumentsBlock";
import CommentsBlock from "../chunks/CommentsBlock/CommentsBlock";
import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import TypeReturnBlock from "../chunks/TypeReturnBlock/TypeReturnBlock";
import ShippingDetailsBlock from "../chunks/ShippingDetailsBlock/ShippingDetailsBlock";

export default {
  name: "StepA",
  components: {
    ShippingDetailsBlock,
    TypeReturnBlock,
    // ShippingCompanyBlock,
    ShipmentDetailsBlock,
    DocumentsBlock,
    CommentsBlock,
    DefaultCheckbox,
  },

  props: {
    ordersInbound: Object,
    blockLabelChoice: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  mounted() {

  },
}
</script>

<style scoped>

</style>
