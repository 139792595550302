<template>
  <div class="fragment">
    <OrdersInboundCreateContent
        :ordersInbound="ordersInbound"
        :blockLabelChoice="blockLabelChoice"
        :disabledWarehouse="disabledWarehouse"
        @changeUser="changeUser"
        @create="save"
        @saveInDraft="saveInDraft"
    />
  </div>
</template>

<script>
import OrdersInboundCreateContent from "./OrdersInboundCreateContent/OrdersInboundCreateContent";
import {OrdersInbound} from "../../models/OrdersInbound";
import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
import {ordersInboundMixin} from "../../../../../mixins/ordersInbound/ordersInboundMixin";

export default {
  name: "OrdersInboundCreate",
  components: {
    OrdersInboundCreateContent,
  },

  mixins: [
    proformMixins,
    ordersInboundMixin,
  ],

  watch: {
    loadUserRoleAndAuthorizedData: function (val) {
      if (val) {
        this.getUserData()
      }
    },

    returnType() {
      this.checkChangeReturnType()
    },

    downloadPermissions() {
      this.checkPermission()
      if (this.checkPermission()) {
        this.checkReturnCreatePermission()
      }
    },
  },

  data() {
    return {
      ordersInbound: new OrdersInbound(),
      blockLabelChoice: false,
      disabledWarehouse: false,
    }
  },

  mounted() {

    if(this.downloadPermissions){
      if(!this.checkPermission()) {
        return
      }
      else {
        if (!this.checkReturnCreatePermission()) return
      }
    }

    if (this.loadUserRoleAndAuthorizedData) {
      this.getUserData()
    }

    /**
     * Set user if create inbound with return goods Id
     */
    if(this.$route.query.idReturned) {
      this.ordersInbound.setIdReturned(this.$route.query.idReturned)
      //this.blockLabelChoice = this.isAdmin
      this.disabledWarehouse = true
    }
    if(this.$route.query.returnedTrack) {
      this.ordersInbound.setTrackingNumber(this.$route.query.returnedTrack)
    }
    if(this.$route.query.returnedUserId && this.isAdmin) {
      this.$store.dispatch('fetchUsersFromFilter', `?filter[UserId]=${this.$route.query.returnedUserId}`)
          .then(response => {
        this.changeUser(this.getRespPaginateData(response)[0])
      })
    }

    this.setOrderSystemTypesAndDeliverySystemByType(this.$route.params.type, true)

    this.getProductsConditionFc()

    this.initializeProductProformArray(this.ordersInbound.data.ProformProducts)
    this.initializeProductProformArray(this.ordersInbound.data.AddedProducts)
  },

  methods: {

    getUserData() {
      if (this.isCurrentUserRoleUser) {
        let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
        this.ordersInbound.data.User.setUser(userData)
      }
    },

  },
}
</script>

<style scoped>

</style>
