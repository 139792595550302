
<template>
  <div class="fragment">
    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            :backgroundImage="'shipments'"
        >
          <template slot="body">
            <div class="card-detail-left__date">
              {{ new Date() | moment("DD MMM, YYYY") }}
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_backTo', ])"></div>
                  <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse" class="order-create__head-back">
                    <LinkBack
                        :value="$t('ordersInbound_backTo.localization_value.value')"
                    />
                  </router-link>
                </div>
              </div>

              <template
                  v-if="$route.params.type === ORDER_INBOUND_TYPE.FBM.value"
              >
                <div class="order-create__head-title color-lightBlue"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newShipmentToFBM', ])"></div>
                  {{$t('ordersInbound_newShipmentToFBM.localization_value.value')}}
                </div>
                <div class="order-create__head-txt"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newShipmentToFBMDesc', ])"></div>
                  {{$t('ordersInbound_newShipmentToFBMDesc.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.type === ORDER_INBOUND_TYPE.FBA.value"
              >
                <div class="order-create__head-title color-gray"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newShipmentToFBA', ])"></div>
                  {{$t('ordersInbound_newShipmentToFBA.localization_value.value')}}
                </div>
                <div class="order-create__head-txt"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newShipmentToFBMAarehouseDesc', ])"></div>
                  {{$t('ordersInbound_newShipmentToFBADesc.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value"
              >
                <div class="order-create__head-title color-red"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newReturnFBM', ])"></div>
                  {{$t('ordersInbound_newReturnFBM.localization_value.value')}}
                </div>
                <div class="order-create__head-txt"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newReturnFBMDesc', ])"></div>
                  {{$t('ordersInbound_newReturnFBMDesc.localization_value.value')}}
                </div>
              </template>

              <template
                  v-if="$route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"
              >
                <div class="order-create__head-title color-red"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newReturnFBA', ])"></div>
                  {{$t('ordersInbound_newReturnFBA.localization_value.value')}}
                </div>
                <div class="order-create__head-txt"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ordersInbound_newReturnFBADesc', ])"></div>
                  {{$t('ordersInbound_newReturnFBADesc.localization_value.value')}}
                </div>
              </template>

              <div class="order-create__head-steps" v-if="!isAdmin">
                <ProgressSteps
                    :class="{'progress-step-shipments': $route.params.type !== ORDER_INBOUND_TYPE.FBMReturn.value}"
                    :options="progressStepsSetting"
                    :step="activeStep"
                    @changeStep="stepChange"
                    @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>
          </template>

          <template slot="body">

            <div class="order-create__section">
              <div class="custom-row" v-if="isAdmin">
                <div class="custom-col custom-col--33 custom-col--md-100">
                  <UserSelect
                      :validationUser="ordersInbound.data.User.validation.userId"
                      :validationTxtUser="ordersInbound.data.User.validationTxt.userId"
                      :validationTranslateUser="ordersInbound.data.User.validationTranslate.userId"
                      :userSelected="ordersInbound.data.User.user"
                      @changeUser="(user) => $emit('changeUser', user)"
                      :typeSelect="'users'"
                      :userEmail="true"
                  />
                </div>
              </div>
            </div>

            <StepA
                v-if="activeStep === 1 || isAdmin"
                :ordersInbound="ordersInbound"
                :blockLabelChoice="blockLabelChoice"
                @typeReturnChanged="checkStepsTypeChange()"
            />

            <StepB
                v-if="activeStep === 2 || isAdmin"
                :ordersInbound="ordersInbound"
                :user="ordersInbound.data.User"
                :disabledWarehouse="disabledWarehouse"
            />

            <StepC
                v-if="(activeStep === 3 &&
                      ($route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value)
                      && (ordersInbound.getReturnType() === ordersInbound.data.returnTypes.buyLabel))
                      && !isAdmin"
                :ordersInbound="ordersInbound"
            />


            <template v-if="(activeStep === 3 && ($route.params.type !== ORDER_INBOUND_TYPE.FBMReturn.value) ||
                      ((activeStep === 4 && ordersInbound.getReturnType() === ordersInbound.data.returnTypes.buyLabel) ||
                      (activeStep === 3 && ordersInbound.getReturnType() !== ordersInbound.data.returnTypes.buyLabel))
                      && ($route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value)) &&
                      !isAdmin">
              <StepFinal
                      :ordersInbound="ordersInbound"
                      :proformProducts="ordersInbound.data.ProformProducts"
                      :user="ordersInbound.data.User"
                      @changeStep="step => {activeStep = step}"
              />


              <div class="order-create__title-horizontal"
                   v-if="Object.keys(ordersInbound.data.labelPrice).length > 0 || $store.getters.getOrdersInboundLoadingPrice"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
                <TitleHorizontal
                        :value="$t('express_Shipping.localization_value.value')"
                />
              </div>

              <div class="pt-2 pb-3" v-if="$store.getters.getOrdersInboundLoadingPrice">
                <DotsShadowPreloader/>
              </div>

              <template v-if="Object.keys(ordersInbound.data.labelPrice).length > 0 && !$store.getters.getOrdersInboundLoadingPrice">
                <LabelPrice
                        :labelPrice="ordersInbound.data.labelPrice"
                        :bigCards="true"
                        :dearCustomer="false"
                />
              </template>
            </template>


          </template>
          <template slot="footer">
            <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_cancel', 'common_Previous', 'common_Next'])"></div>
              <div class="order-create__footer-link">
                <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse"
                             class="site-link site-link--alt"
                             v-if="activeStep == 1"
                >
             {{ $t('common_cancel.localization_value.value') }}
                </router-link>
                <span class="site-link site-link--alt"
                      @click="prevPageHandler()"
                      v-if="activeStep != 1"
                >
             {{ $t('common_Previous.localization_value.value') }}
            </span>
              </div>

              <div class="order-create__footer-btn ml-auto"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit"
                     @click="editTranslate([
                      'common_SaveInDrafts',
                      'common_drafts',
                      'common_savingInDrafts',
                      'common_create'
                   ])"></div>
                <MainButton
                    v-if="(activeStep === 3 && ($route.params.type !== ORDER_INBOUND_TYPE.FBMReturn.value) ||
                          activeStep === 4 && ($route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value))"
                    class="order-create__footer-btn-i secondary"
                    :value="$t('common_SaveInDrafts.localization_value.value')"
                    :tooltip="true"
                    @click.native="$emit('saveInDraft', {type: $route.params.type, draft: true, isAdmin: isAdmin})"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersInboundBtn}"
                >
                  <template slot="tooltip">
                    <p>
                      <b>{{ $t('common_drafts.localization_value.value') }}</b>
                    </p>
                    <p>
                      {{ $t('common_savingInDrafts.localization_value.value') }}
                    </p>
                  </template>
                </MainButton>


                <MainButton
                    class="order-create__footer-btn-i"
                    :value="$t('common_Next.localization_value.value')"
                    @click.native="nextPageHandler()"
                    v-if="activeStep !== progressStepsSetting.length && !isAdmin"
                />
                <template v-else>
                  <MainButton
                          v-if="ordersInbound.data.returnTypes.buyLabel === ordersInbound.data.returnType && !getStatusCreateLabel"
                          class="order-create__footer-btn-i wfc"
                          :value="$t('express_BuyLabel.localization_value.value')"
                          @click.native="getLabelPriceFromCard"
                          v-bind:class="{'disabled-btn' : $store.getters.getOrdersInboundBtn}"
                  />

                  <MainButton
                          v-else
                          class="order-create__footer-btn-i"
                          :value="$t('common_create.localization_value.value')"
                          @click.native="$emit('create', {type: $route.params.type, isAdmin: isAdmin, createLabel: getStatusCreateLabel})"
                          v-bind:class="{'disabled-btn' : $store.getters.getOrdersInboundBtn}"
                  />
                </template>

              </div>
            </div>
          </template>
        </CardRightBlock>
      </div>
    </div>

  </div>
</template>

<script>

import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps";
import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
import StepA from "../../components/StepA/StepA";
import StepB from "../../components/StepB/StepB";
import StepC from "../../components/StepC/StepC";
import StepFinal from "../../components/StepFinal/StepFinal";
import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
import {ordersInboundMixin} from "../../../../../../mixins/ordersInbound/ordersInboundMixin";
import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import LabelPrice from "../../../../../coreComponents/LabelPrice/LabelPrice";

export default {
  name: "OrdersInboundCreateContent",
  components: {
    LabelPrice,
    DotsShadowPreloader,
    TitleHorizontal,
    CardLeftBlock,
    LinkBack,
    ProgressSteps,
    MainButton,
    CardRightBlock,
    StepA,
    StepB,
    StepC,
    StepFinal,
    UserSelect,
  },

  mixins: [
    tabsMixins,
    ordersInboundMixin,
  ],

  props: {
    ordersInbound: Object,
    blockLabelChoice: {
      type: Boolean,
      default: false,
    },
    disabledWarehouse: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    activeStep(newVal) {
      if(newVal === 4){
        this.ordersInbound.data.labelPrice = {}
      }
    },
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,

      activeStep: 1,
      progressStepsSetting: [
        this.$t('ordersInbound_shipping.localization_value.value'),
        this.$t('ordersInbound_products.localization_value.value'),
        this.$t('ordersInbound_confirmation.localization_value.value'),
      ],

      hasCreateLabel: false,
    }
  },

  mounted() {
    if(this.$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value){
      this.checkStepsTypeChange()
    }
  },

  methods: {
    checkStepsTypeChange() {
      if (this.ordersInbound.getReturnType() !== this.ordersInbound.data.returnTypes.buyLabel) {
        this.progressStepsSetting = [
          this.$t('ordersInbound_shipping.localization_value.value'),
          this.$t('ordersInbound_products.localization_value.value'),
          this.$t('ordersInbound_confirmation.localization_value.value'),
        ]
      }
      else {
        this.progressStepsSetting = [
          this.$t('ordersInbound_shipping.localization_value.value'),
          this.$t('ordersInbound_products.localization_value.value'),
          this.$t('ordersInbound_shippingDetails.localization_value.value'),
          this.$t('ordersInbound_confirmation.localization_value.value'),
        ]
      }
    }
  }

}
</script>

<style scoped>

@media (min-width: 993px) {
  .progress-step-shipments {
    /*max-width: 520px;*/
  }
}

@media (max-width: 1120px) {

  .detail-page__right {
    min-height: calc(100vh - 250px);
  }
}
</style>
