<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['ordersInbound_typeReturn'])"></div>
      {{ $t('ordersInbound_typeReturn.localization_value.value') }}
<!--      Type Return-->
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ordersInbound_iHasLabel', 'ordersInbound_buyLabel'])"></div>
        <LargeSwitch
            :activeValue="ordersInbound.data.returnTypes.iHasLabel"
            :inactiveValue="ordersInbound.data.returnTypes.buyLabel"
            :labelActiveValue="$t('ordersInbound_iHasLabel.localization_value.value')"
            :labelInactiveValue="$t('ordersInbound_buyLabel.localization_value.value')"
            :checkedItem="ordersInbound.data.returnType"
            :name="'orderCreateSwitch'"
            v-bind:class="{
              'disable-large-switch': editAdmin || blockLabelChoice
            }"
            @change="(val) => setReturnTypeOnChange(val)"
        />
      </div>
    </div>

    <!--<div class="order-create__row custom-row">-->
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
           <!--v-if="$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value ||-->
                   <!--$route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value"-->

           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['ordersInbound_condition'])"></div>-->
        <!--<DefaultSelect-->
                <!--:label="$t('ordersInbound_condition.localization_value.value')"-->
                <!--:options="ordersInbound.data.condition"-->
                <!--:optionsLabel="'name'"-->
                <!--v-bind:class="{'ui-no-valid': ordersInbound.validation.conditionSelected}"-->
                <!--:errorTxt="$t(`${ordersInbound.validationTranslate.conditionSelected}.localization_value.value`)"-->
                <!--:error="ordersInbound.validation.conditionSelected"-->
                <!--:selected="ordersInbound.data.conditionSelected"-->
                <!--@change="(val) => ordersInbound.setConditionSelected(val)"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import LargeSwitch from "../../../../../../UI/radiobuttons/LargeSwitch/LargeSwitch";
import {ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";

export default {
  name: "TypeReturnBlock",
  components: {
    LargeSwitch,
  },
  props: {
    ordersInbound: Object,
    editAdmin: {
      type: Boolean,
      default: false
    },
    blockLabelChoice: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  methods: {
    setReturnTypeOnChange(val) {
      this.ordersInbound.setReturnType(val)
      this.$emit('typeReturnChanged')
    },
  }
}
</script>

<style scoped>

</style>
